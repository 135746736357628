const numberFormatters = {
  catchAll: new Intl.NumberFormat('en-EN'),
  bg: new Intl.NumberFormat('bg-BG'),
  de: new Intl.NumberFormat('de-DE'),
  el: new Intl.NumberFormat('el-GR'),
  en: new Intl.NumberFormat('en-EN'),
  es: new Intl.NumberFormat('es-ES'),
  fr: new Intl.NumberFormat('fr-FR'),
  it: new Intl.NumberFormat('it-IT'),
  pl: new Intl.NumberFormat('pl-PL'),
  sv: new Intl.NumberFormat('se-SE'),
  ro: new Intl.NumberFormat('ro-RO'),
};

module.exports = {
  logBuild: false,
  locales: [
    'bg',
    'de',
    'el',
    'en',
    'es',
    'fr',
    'it',
    'pl',
    'sv',
    'ro',
    'catchAll',
  ],
  defaultLocale: 'pl',
  interpolation: {
    prefix: '%{',
    suffix: '}',
    format: (value, format, lang) => {
      if (format === 'number') return numberFormatters[lang].format(value);
      return value;
    },
  },
  keySeparator: false,
  nsSeparator: '::',
  defaultNS: 'common',
  pages: {
    '*': [
      'navbar',
      'footer',
      'cookieBar',
      'common',
      'encouragementSection',
      'ErrorBoundary',
      'zaufaneBadge',
    ],
    '/': ['features', 'home', 'references', 'iso27001Badge', 'passwordInput'],
    '/404': ['404'],
    '/birthday-sms': ['birthdaySms'],
    '/case-studies': ['caseStudies'],
    '/company': ['company'],
    '/contact': ['contact', 'tryOutForFreeSection', 'registration'],
    '/fast-sms': ['fastSms'],
    '/hlr': ['hlr'],
    '/integrations': ['integrations'],
    '/libraries': ['libraries', 'encouragementSection'],
    'rgx:^/lp': [
      'registration',
      'tryOutOurServicesSection',
      'passwordInput',
      'landingPage',
    ],
    '/mail2sms': ['mail2sms'],
    '/megalanding-confirmation': ['tryOutOurServicesSection'],
    '/mms-sending': [],
    '/mobile-marketing-tools': ['mobileMarketingTools'],
    '/newsletter': ['newsletter'],
    '/newsletter-sms': ['newsletterSms', 'pricing'],
    '/opt-out-sms': ['optOutSms'],
    '/press': ['press'],
    'rgx:^/pricing': [
      'chatWithUs',
      'encouragementSection',
      'pricing',
      'tryOutForFreeSection',
    ],
    '/receive-sms': ['iso27001Badge', 'receiveSms'],
    '/safety': ['safety'],
    '/schedule-sms': ['scheduleSms'],
    '/short-link': ['shortLink'],
    'rgx:^/sms': [
      'advantagesOfSmsapi',
      'bulkSms',
      'bulkSmsApiSection',
      'bulkSmsBusinessCommunicationSection',
      'bulkSmsFeaturesSection',
      'bulkSmsTabletOldSection',
      'bulkSmsToolsSection',
      'customerPortalPlatformsSection',
      'features',
      'integrationsSharedSection',
      'mobileMessaging',
      'tryOutOurServicesSection',
      'home',
    ],
    '/sms-api': ['smsApi'],
    '/sms-authenticator': ['smsAuthenticator'],
    '/sms-campaigns': ['smsCampaigns'],
    '/sms-gateway': ['smsGateway'],
    'rgx:^/sms-marketing': [
      'smsMarketing',
      'testimonialsSection',
      'encouragementSection',
    ],
    '/sms-notifications': ['smsNotifications', 'integrationsSharedSection'],
    '/video-tutorial': ['videoTutorial', 'features'],
    '/vms': [],
    'rgx:^/registration': ['registration', 'passwordInput'],
    'rgx:^/registration-next': ['registration', 'passwordInput'],
    '/rich-media-sms': ['richMediaSms'],
    'rgx:^/privacy-policy': ['privacyPolicy'],
    '/two-way-communication': ['twoWayCommunication'],
    '/whatsapp': ['whatsapp'],
    '/individual-offer': ['individualOffer', 'registration', 'pricing'],
    '/other-features': ['otherFeatures'],
    '/targeting': ['targeting'],
    '/contests': ['contests'],
    '/discount-codes': ['discountCodes'],
    '/inspirations': ['inspirations'],
    '/tjanster': ['home', 'features'],
    '/personalization': ['personalization'],
    '/sub-accounts': ['subAccounts'],
    '/mobile-app': ['mobileApp'],
    'rgx:^/terms': ['terms'],
    '/referral-program': ['referralProgram'],
  },
};
