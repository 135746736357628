const REFERRER_LOCAL_STORAGE_KEY = 'referrer';

export const getReferrer = (): string | undefined => {
  return window?.localStorage?.getItem(REFERRER_LOCAL_STORAGE_KEY) || undefined;
};

export const initReferrer = (): void => {
  const isReferrerSetAlready = !!getReferrer();

  if (!isReferrerSetAlready) {
    window?.localStorage?.setItem(
      REFERRER_LOCAL_STORAGE_KEY,
      window.document.referrer,
    );
  }
};
